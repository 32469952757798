import React from "react"
import { themeGet } from 'styled-system'
import styled from 'styled-components'
import Text from '../Text'
import {PostLink} from "../Link"

const CardList = styled.div`
	align-items: flex-start;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: repeat(auto, auto);
	grid-column-gap: 13px;
	grid-row-gap: 2em;
	@media (max-width: ${themeGet('breakpoints.2')}) { 
		grid-template-columns: repeat(2, 1fr);
	}
	@media (max-width: ${themeGet('breakpoints.0')}) { 
		grid-template-columns: repeat(1, 1fr);
	}
`
const Card = styled.div`
	width: 100%;
	height: 287px;
	border: 1px solid #eee;
	border-radius: ${themeGet('radii.3')};
	background: #FFFFFF;
	box-shadow: 0 1px 24px 0 rgba(0,0,0,0.15);
`
const CardImage = styled.div`
	height: 153px;
	border-top-left-radius:  ${themeGet('radii.3')};
    border-top-right-radius:  ${themeGet('radii.3')};
`

const CardText = styled.div`
	padding: 16px;
`
const CardHeader = styled.p`
	font-size: ${themeGet('fontSizes.3')};
	font-family: "GraphikMedium";
	line-height: 24px;
	margin-top: 10px;
`

const RelateHeader = styled.p`
	font-size: 1.33em;
	font-family: "GraphikMedium";
`

class RelatedPost extends React.Component {
	renderCardList(){
		const {data, current, maxNum } = this.props
		if(data!==""){
			return data.map((item, i) => {
				let categories = []
				if (i<maxNum) {
					for (let i=0; i< current.frontmatter.categories.length; i++){
						for(let j=0; j<item.node.frontmatter.categories.length; j++){
							if(item.node.frontmatter.categories[j] === current.frontmatter.categories[i]){
								categories.push(item.node.frontmatter.categories[j])
								categories.push(", ")	
							}
						}
					}
					
					categories.splice(-1,1)

					return (
						<Card key={i}>
							<CardImage style={{ background: `url(${item.node.frontmatter.image}) center center`, backgroundSize: 'cover'}} /> 
							<PostLink to={item.node.fields.slug}>
								<CardText>
									<Text fontSize={"13px"}>{categories}</Text>
									<CardHeader>{item.node.frontmatter.title}</CardHeader>
								</CardText>
							</PostLink>
						</Card>
					)
				}
		    })
		}

	}
	render() {
		return (
			<div>
				<RelateHeader>Related</RelateHeader>
				<CardList>
					{this.renderCardList()}
				</CardList>
			</div>
		)
		 
	}
}

export default RelatedPost

