import React from "react"
import { Link } from 'gatsby'
import { themeGet } from 'styled-system'
import styled from 'styled-components'


const NavigationBar = styled.div`
	align-items: flex-start;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: repeat(auto, auto);
`

const Prev = styled.div`
`
const Next = styled.div`
	text-align: right;
	align-items: right;	
`
const NavLabel = styled.h4`
	text-transform: uppercase;
	font-family: "GraphikMedium";
	color: ${themeGet('colors.contrast.1')};
	font-size: ${themeGet('fontSizes.1')};
	margin: 0;
`

const NavText = styled.p`
	font-size: ${themeGet('fontSizes.4')};

	& a {
		color: ${themeGet('colors.contrast.4')};
		text-decoration: none;
	}

	& a:hover {
		color: ${themeGet('colors.contrast.2')};
	}
`

class Navigation extends React.Component {
	render() {
		const {prev, next} = this.props
		if(prev === "") {
			return(
				<NavigationBar>
					<Prev>
						
					</Prev>
					<Next>
						<NavLabel>Next</NavLabel>
						<NavText><Link to={next.node.fields.slug}>{next.node.frontmatter.title}</Link></NavText>
					</Next>
				</NavigationBar>
			)
		} else if(next === ""){
			return(
				<NavigationBar>
					<Prev>
						<NavLabel>Previous</NavLabel>
						<NavText><Link to={prev.node.fields.slug}>{prev.node.frontmatter.title}</Link></NavText>
					</Prev>
					<Next>
					</Next>
				</NavigationBar>
			)
		} else {
			return (
				<NavigationBar>
					<Prev>
						<NavLabel>Previous</NavLabel>
						<NavText><Link to={prev.node.fields.slug}>{prev.node.frontmatter.title}</Link></NavText>
					</Prev>
					<Next>
						<NavLabel>Next</NavLabel>
						<NavText><Link to={next.node.fields.slug}>{next.node.frontmatter.title}</Link></NavText>
					</Next>
				</NavigationBar>
			)
		} 
	}
}

export default Navigation

