import React from "react"
import { graphql } from 'gatsby'
import { themeGet } from 'styled-system'
import styled from 'styled-components'
import MDXRenderer from "gatsby-mdx/mdx-renderer";

import Hero from "../components/Hero"
import Navigation from "../components/Navigation"
import RelatedPost from "../components/RelatedPost"
import {ListLink} from "../components/Link"
import Layout from "../layouts"


const Content = styled.div`
	width: 65%;
	margin: 0 auto;
	@media (max-width: ${themeGet('breakpoints.1')}) { 
		width: 100%;
	}

	& p, ul, li {
		font-size: 20px;
		line-height: 34px;
	}

	& h1 {
		font-size: ${themeGet('headings.9')};
		margin-bottom: 0;
		
		@media (max-width: ${themeGet('breakpoints.4')}) {
			font-size: ${themeGet('headings.8')};
		}
		@media (max-width: ${themeGet('breakpoints.2')}) {
			font-size: ${themeGet('headings.6')};
		}
		@media (max-width: ${themeGet('breakpoints.1')}) {
			margin: 0.5em 0;
		}
	}

	& h3 {
		font-size: 2.4em;
		font-family: "Graphik";
	}

	& h4 {
		font-size: 2.4em;
		font-family: "GraphikMedium";
	}

	& p, img {
		width: 100%;
	}
`
const Image = styled.img`
	margin: 5em 0;
	width: 100%;
`

const TagList = styled.div`
	margin: 5em 0;
	& span {
		padding: 12px 24px;
		font-size: 1em;
		background: #F2F2F2;
		border-radius: 8px;
		margin-right: 8px;
	}
`

class ArticlePost extends React.Component {
	renderTags() {
		const {current} = this.props.data;

        return current.frontmatter.tags.map((item, i) => {
			return (
				<ListLink to={`/tags/${item}`}><span>{item}</span></ListLink>
			)
	    })
	}

	render() {
		const {current, allMdx} = this.props.data;
		let lists = allMdx.edges
	    let prev = ""
	    let next = ""
	    let relatedList = []

	    for(let i = 0;i < lists.length; i++){
			if (lists[i].node.frontmatter.title===current.frontmatter.title) {
				if (i===0) {
					next = lists[i+1]
				} else if (i===lists.length-1){
					prev = lists[i-1]
				} else {
					prev = lists[i-1]
					next = lists[i+1]
				}
			} else {	
				for(let j = 0;j< current.frontmatter.categories.length; j++) {
		    		if(lists[i].node.frontmatter.categories.indexOf(current.frontmatter.categories[j]) > -1){
			            relatedList.push(lists[i]);
			            break;
			        }
	    		}   
	    	}   
	    }
		return (
			<Layout>
		 		<Hero data={current.frontmatter} />
		 		<Image src="/img/pen.png"/>
		 		<Content>
		 			<MDXRenderer>{current.code.body}</MDXRenderer>
		 			<TagList>
		 				{this.renderTags()}
		 			</TagList>
		 		</Content>
		 		<Navigation prev={prev} next={next}/>
		 		<RelatedPost data={relatedList} current={current} maxNum={3}/>
		  	</Layout>
		)
	}
}

export default ArticlePost

export const pageQuery = graphql`
  query ArticlePostQuery($id: String) {
    current: mdx(id: { eq: $id }) {
      	id
      	frontmatter {
       		title
			templateKey
			date(formatString: "MMMM DD - YYYY")
			image
			tags
			categories
      	}
      	code {
        	body
      	}
    }
    allMdx(
		sort: { order: DESC, fields: [frontmatter___date] },
		filter: {frontmatter: {templateKey: {eq: "article-post" }}}){
		edges {
			node {
				id
				fields {
					slug
				}
				frontmatter {
					title
					templateKey
					description
					date(formatString: "MMMM DD - YYYY")
					image
					tags
					categories 
				}
			}
		}
	}
  }
`;